import tippy from 'tippy.js';
import ConnectEngine from 'namespace.js';

ConnectEngine.Tooltips = {
  defaults: {
    touch: false,
    appendTo: document.body,
    delay: [250, 0],
    zIndex: 3,
    maxWidth: '400px',
    placement: 'auto',
    animation: false,
    theme: 'light-border'
  },
  lazy: function(selector, options) {
    $(document).on('mouseenter', selector, function(){ ConnectEngine.Tooltips.add(this, options) });
  },

  add: function(element, options) {
    element = $(element);
    let tooltip;

    // Bail out if we've already added a tooltip
    if (element.data('connectEngineTooltip')) { return }
    element.data('connectEngineTooltip', true);

    // Allow the tooltip to trigger after the framework is loaded, but not to if the user has moused away
    var showAfterFrameworkLoad = true;
    element.one('mouseleave', function(){ showAfterFrameworkLoad = false });

    // Generate the tooltip contents
    if (options.content) {
      tooltip = options.content(element)
    }

    if (!tooltip) {
      return;
    }

    this.loadFrameworks().then(function(){
      var instance = tippy(element[0], $.extend({}, ConnectEngine.Tooltips.defaults, options, { content: tooltip }));

      // Now that the library is downloaded, show the tooltip if we're still hovered
      if (showAfterFrameworkLoad) {
        instance.show();
      }
    })
  },

  // Optionally dynamically load the tooltip framework
  loadFrameworks: function() {
    return new $.Deferred().resolve();
  }
}

ConnectEngine.Tooltips.lazy(':not(.truncated)[title]', {
  content: getContent,
  onShow: function(instance) {
    choosePlacement(instance)
  }
})

ConnectEngine.Tooltips.lazy('.truncated[title]', {
  content: getContent,
  onShow: abortUnlessTruncated
})

function choosePlacement(instance) {
  var reference = $(instance.reference)
  if (reference.closest('.menu').length) {
    instance.setProps({placement: 'right'})
  } else if (reference.closest('#site_header').length) {
    instance.setProps({placement: 'bottom'})
  } else if (reference.closest('.input').length) { // Don't cover nearby inputs, and look better with 'fill' grouped inputs
    instance.setProps({placement: 'bottom'})
  }
}

function getContent(reference){
  const title = $(reference).attr('title');
  $(reference).removeAttr('title');
  return title;
}

function abortUnlessTruncated(instance){
  var element = $(instance.reference)
  if (element.css('text-overflow') == 'ellipsis' && element.prop('scrollWidth') <= element.prop('offsetWidth')) {
    return false
  }
}
