/* eslint no-console:0 */

import "@rails/actiontext";
import Trix from "trix";
import Tribute from "tributejs";

const TRIGGER_AT_MENTION = "x-at-mention";
const TRIGGER_HASH_MENTION = "x-hash-mention";
const MENTION_CONTENT_TYPE = "application/vnd-at-mention";

function fetchLinkable(url, callback) {
  fetch(url)
    .then((response) => response.json())
    .then((linkables) => callback(linkables))
    .catch((error) => {
      console.error(error);
      callback([]);
    });
}

function initializeTribute(trixEditor, trixField, endpoints) {
  const { mentionsEndpoint, reflinksEndpoint } = endpoints;

  function pasteHtml(html, startPos, endPos) {
    const position = trixEditor.getPosition();
    trixEditor.setSelectedRange([position - endPos + startPos, position]);
    trixEditor.deleteInDirection("backward");
  }

  function onTributeReplaced(e) {
    const { item } = e.detail;
    if (!item) return;

    const attachmentData = item.original;

    const attachment = new Trix.Attachment({
      sgid: attachmentData.sgid,
      content: attachmentData.content,
      contentType: MENTION_CONTENT_TYPE,
    });
    trixEditor.insertAttachment(attachment);
    trixEditor.insertString(" ");
  }

  function fetchUsers(text, callback) {
    const params = mentionsEndpoint.searchParams;
    params.set("q", text);
    mentionsEndpoint.search = params.toString();
    fetchLinkable(mentionsEndpoint.toString(), callback);
  }

  function fetchRefs(text, callback) {
    const params = reflinksEndpoint.searchParams;
    params.set("q", text);
    reflinksEndpoint.search = params.toString();
    fetchLinkable(reflinksEndpoint.toString(), callback);
  }

  const tributeCollection = [];

  if (mentionsEndpoint) {
    tributeCollection.push({
      trigger: "@",
      allowSpaces: true,
      lookup: "name",
      values: fetchUsers,
    });
  }

  if (reflinksEndpoint) {
    tributeCollection.push({
      trigger: "#",
      allowSpaces: true,
      lookup: "name",
      values: fetchRefs,
    });
  }

  const tribute = new Tribute({ collection: tributeCollection });
  tribute.attach(trixField);
  tribute.range.pasteHtml = pasteHtml;

  trixField.addEventListener("tribute-replaced", onTributeReplaced);

  // prevent trix from accepting file uploads
  trixField.addEventListener("trix-file-accept", (event) => {
    event.preventDefault();
  });

  // prevent trix from accepting copy-and-pasted attachments
  trixField.addEventListener("trix-attachment-add", function (event) {
    const { attachment } = event;
    if (attachment.getContentType() != MENTION_CONTENT_TYPE) {
      attachment.remove();
    }
  });

  return tribute;
}

function addMentionButtons(trixField, tribute, endpoints) {
  const { mentionsEndpoint, reflinksEndpoint, reflinksModelName } = endpoints;

  const buttons = [];
  if (mentionsEndpoint) {
    buttons.push(
      `<button type="button" data-trix-action="${TRIGGER_AT_MENTION}" class="trix-button" title="Mention a User" tabindex="-1">@</button>`
    );
  }

  if (reflinksEndpoint) {
    buttons.push(
      `<button type="button" data-trix-action="${TRIGGER_HASH_MENTION}" class="trix-button" title="Reference a ${reflinksModelName}" tabindex="-1">#</button>`
    );
  }

  if (buttons.length < 1) return;

  const renderedButtons = `<span class="trix-button-group trix-button-group--mention-tools" data-trix-button-group="mention-tools">${buttons.join(
    ""
  )}</span>`;

  trixField.toolbarElement
    .querySelector(".trix-button-group")
    .insertAdjacentHTML("afterend", renderedButtons);

  trixField.addEventListener("trix-action-invoke", function (event) {
    let collectionIndex;
    switch (event.actionName) {
      case TRIGGER_AT_MENTION:
        collectionIndex = tribute.collection.findIndex(
          (collection) => collection.trigger === "@"
        );
        break;

      case TRIGGER_HASH_MENTION:
        collectionIndex = tribute.collection.findIndex(
          (collection) => collection.trigger === "#"
        );
        break;
    }

    tribute.showMenuForCollection(event.target, collectionIndex);
  });
}

function getEndpoints(trixField) {
  const parentForm = trixField.closest("form");
  if (!parentForm) {
    console.warn({ trixEditor, parentForm });
    throw new Error(`Unable to find parent form to load linkable endpoints`);
  }

  const endpoints = {
    mentionsEndpoint: undefined,
    reflinksEndpoint: undefined,
    reflinksModelName: undefined,
  };

  if (parentForm.dataset.mentionsEndpoint) {
    endpoints.mentionsEndpoint = new URL(
      parentForm.dataset.mentionsEndpoint,
      window.location
    );
  }

  if (parentForm.dataset.reflinksEndpoint) {
    endpoints.reflinksEndpoint = new URL(
      parentForm.dataset.reflinksEndpoint,
      window.location
    );

    endpoints.reflinksModelName =
      parentForm.dataset.reflinksModelName || "Document";
  }

  return endpoints;
}

function initTrix(event) {
  const trixField = event.target;
  const trixEditor = trixField.editor;

  const endpoints = getEndpoints(trixField);
  if (!endpoints.mentionsEndpoint && !endpoints.reflinksEndpoint) return;

  const tribute = initializeTribute(trixEditor, trixField, endpoints);
  addMentionButtons(trixField, tribute, endpoints);
}

if (!window.Modernizr) {
  console.warn("Modernizr not loaded so skipping trixLinkables init");
} else if (
  !window.Modernizr.mutationobserver ||
  !window.Modernizr.customelements ||
  !window.Modernizr.fetch
) {
  console.warn("Missing browser features so skipping trixLinkables init");
} else {
  addEventListener("trix-initialize", initTrix);
}
